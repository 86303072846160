import {getSetting} from '@woocommerce/settings';
import {PaymentMethodMessagingElement, Elements} from "@stripe/react-stripe-js";
import {registerPlugin} from '@wordpress/plugins';
import {ExperimentalOrderMeta, TotalsWrapper} from '@woocommerce/blocks-checkout';
import {initStripe, isCartPage, isKlarnaAvailable} from "../../../payment-methods/util";
import {SilentErrorBoundary} from "../../../components/shared";

const data = getSetting('stripeBNPLCart_data').stripe_klarna;

if (isCartPage() && data && data.cartEnabled) {
    const requiredParams = data.requiredParams;
    const KlarnaCartMessage = ({cart}) => {
        const {cartTotals} = cart;
        const {currency_code: currency} = cartTotals;
        const options = {
            amount: parseInt(cartTotals.total_price),
            currency: cartTotals.currency_code,
            paymentMethodTypes: ['klarna'],
            ...data.messageOptions
        };

        if (isKlarnaAvailable({requiredParams, currency})) {
            return (
                <TotalsWrapper>
                    <div className={'wc-block-components-totals-item wc-stripe-cart-message-container stripe_klarna'}>
                        <PaymentMethodMessagingElement options={options}/>
                    </div>
                </TotalsWrapper>
            )
        }
        return null;
    }
    const render = () => {
        const Component = (props) => (
            <SilentErrorBoundary>
                <Elements
                    stripe={initStripe}
                    options={{
                        locale: data.elementOptions.locale
                    }}>
                    <KlarnaCartMessage {...props}/>
                </Elements>
            </SilentErrorBoundary>
        );

        return (
            <ExperimentalOrderMeta>
                <Component/>
            </ExperimentalOrderMeta>
        )
    }
    registerPlugin('wc-stripe-blocks-klarna', {render, scope: 'woocommerce-checkout'});
}