export const MandateText = (
    {
        text
    }) => {
    return (
        <div className={'wc-stripe-mandate--container'}>
            <p className={'wc-stripe-mandate-container--text'}>
                {text}
            </p>
        </div>
    )
}