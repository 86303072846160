import {registerPaymentMethod} from '@woocommerce/blocks-registry';
import {getSettings} from "../util";
import {PaymentMethodLabel, PaymentMethod} from "../../components/checkout";
import {canMakePayment, LocalPaymentIntentContent} from "./local-payment-method";

const getData = getSettings('stripe_sepa_data');

if (getData()) {
    registerPaymentMethod({
        name: getData('name'),
        label: <PaymentMethodLabel
            title={getData('title')}
            paymentMethod={getData('name')}
            icons={getData('icon')}/>,
        ariaLabel: 'SEPA',
        placeOrderButtonLabel: getData('placeOrderButtonLabel'),
        canMakePayment: canMakePayment(getData),
        content: <PaymentMethod content={LocalPaymentIntentContent} getData={getData} mandate={!getData('mandate')} mandateText={getData('mandateText')}/>,
        edit: <PaymentMethod content={LocalPaymentIntentContent} getData={getData} mandate={!getData('mandate')} mandateText={getData('mandateText')}/>,
        supports: {
            showSavedCards: true,
            showSaveOption: true,
            features: getData('features')
        }
    })
}